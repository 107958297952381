<template>
    <div class="welcome" :class="{ 'fade-out': isFadingOut }" @animationend="onFadeAnimationEnd">
        <div class="clouds">
            <div class="cloud" id="cloud1">
                <div class="shadow"></div>
            </div>
            <div class="cloud" id="cloud2">
                <div class="shadow"></div>
            </div>
            <div class="cloud" id="cloud3">
                <div class="shadow"></div>
            </div>
        </div>

        <div class="countdown-circle">
            {{ countdown }}
        </div>
        <div class="curtain-container" :class="{ parted: isCurtainOpen }">
            <div class="curtain-left" @animationend="handleCurtainEnd"></div>
            <div class="curtain-right" @animationend="handleCurtainEnd"></div>
        </div>

        <div class="welcome-content" :class="{ 'move-center': isTextCentered }">
            <h1>Welcome to <span class="cxemsFont">CxEMS</span></h1>
            <p class="detailWelcome">
                스마트 에너지 관리로 탄소 배출을 줄이고 <br />
                지속 가능한 미래를 실현합니다.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Welcome",
    data() {
        return {
            countdown: 3,
            intervalId: null,
            isCurtainOpen: false,
            isFadingOut: false,
            isTextCentered: false,
        };
    },
    mounted() {
        this.intervalId = setInterval(() => {
            if (this.countdown > 0) {
                this.countdown--;
            } else {
                clearInterval(this.intervalId);
                this.isCurtainOpen = true;
            }
        }, 1000);
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
    methods: {
        handleCurtainEnd(e) {
            if (e.animationName.startsWith("curtain-right")) {
                this.isTextCentered = true;
                this.isFadingOut = true;
            }
        },
        onFadeAnimationEnd(e) {
            if (e.animationName.startsWith("fadeOut")) {
                this.$emit("close");
            }
        },
    },
};
</script>

<style scoped>
.welcome {
    position: relative;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: #2e579c;
}

/* ------------------- 커튼 컨테이너 ------------------- */
.curtain-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
/* parted 붙으면 애니메이션 시작 */
.curtain-container.parted .curtain-left {
    animation: curtain-left 1.3s forwards ease-in-out;
}
.curtain-container.parted .curtain-right {
    animation: curtain-right 1.3s forwards ease-in-out;
}

/* 왼쪽 커튼 */
.curtain-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    transform-origin: right center;

    background: #64b5f6;
    background-size: 200% 200%;
    background-position: left center;
}

/* 오른쪽 커튼 */
.curtain-right {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    transform-origin: left center;

    background: #64b5f6;
    background-size: 200% 200%;
    background-position: right center;
}

/* 커튼 애니메이션 */
@keyframes curtain-left {
    0% {
        transform: translateX(0%) rotateY(0deg);
    }
    100% {
        transform: translateX(-100%) rotateY(20deg);
    }
}
@keyframes curtain-right {
    0% {
        transform: translateX(0%) rotateY(0deg);
    }
    100% {
        transform: translateX(100%) rotateY(-20deg);
    }
}

/* ------------------ 카운트다운 (우측 하단) ------------------ */
.countdown-circle {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #fff;
    color: #2e579c;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.3rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 9999;
}

/* ------------------ CxEMS 문구 ------------------ */
.welcome-content {
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    transition: left 1s ease, transform 1s ease;
}
.welcome-content.move-center {
    left: 40%;
    transform: translate(-50%, -40%);
}

/* 메인 타이틀 */
.welcome-content h1 {
    font-size: 5rem;
    margin: 0 0 1.2rem 0;
    font-weight: 900;
    /* text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);  */
}

/* 서브 타이틀/메시지 */
.detailWelcome {
    font-size: 2rem;
    line-height: 1.4;
    color: #fff;
    /* text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3); */
}

/* CxEMS 단어만 색 다르게 */
.cxemsFont {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 800;
    /* color: #0099ff; */
    color: #005daa;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

/* ------------------ 흰 배경 페이드아웃 (3초) ------------------ */
@keyframes fadeOut {
    0% {
        background-color: #fff;
        opacity: 1;
    }
    80% {
        background-color: #fff;
        opacity: 1;
    }
    100% {
        background-color: #fff;
        opacity: 0;
    }
}
.fade-out {
    animation: fadeOut 4s forwards ease;
}

/* 구름 */
.clouds {
    display: flex;
    margin-top: 5rem;
}
.cloud {
    background: white;
    /* background: linear-gradient(#f2f9fe 5%, #d6f0fd 100%); */
    border-radius: 100px;
    position: relative;
    margin: 50px auto;
    animation: float 3s infinite ease-in-out alternate;
    z-index: 1000;
}
#cloud1 {
    margin-right: 2rem;
    width: 330px;
    height: 120px;
}
#cloud2 {
    width: 350px;
    height: 120px;
}
#cloud3 {
    width: 270px;
    height: 100px;
}
.cloud:after,
.cloud:before {
    content: "";
    position: absolute;
    background: white;
    z-index: -1;
}

.cloud:after {
    width: 100px;
    height: 100px;
    top: -50px;
    left: 50px;
    border-radius: 100px;
}

.cloud:before {
    width: 180px;
    height: 180px;
    top: -90px;
    right: 50px;
    border-radius: 200px;
}

.shadow {
    width: 300px;
    position: absolute;
    bottom: -10px;
    left: 20px;
    background: #000;
    z-index: -1;
    box-shadow: 0 0 25px 3px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
}

@keyframes float {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-10px);
    }
}
</style>
