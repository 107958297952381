<template>
    <div class="xeUsageChart heightMAX rounded" style="overflow: hidden;">
        <div class="heightMAX" style="padding: 0">
            <!-- <div class="jvectormap-container " style="background-color: transparent; position:relative"> -->
            <drop
                @dragover="handleBodyDragOver(...arguments)"
                @drop="handleBodyDrop(...arguments)"
                class="heightMAX kbet-custom-reset"
            >
                <div class="rounded kbet-widget-energy heightMAX" style="display: block;">
                    <p class="title">{{ $t(widget.title) }}</p>
                    <div class="usage-animation-area">
                        <div class="area-align">
                            <div class="info_area">
                                <dl class="info01">
                                    <dt>{{ $t("에너지 자립률") }}</dt>
                                    <dd>{{ selfRenceRate }}<span>%</span></dd>
                                </dl>
                                <dl class="info-style info02">
                                    <dt>{{ $t("1차에너지 소비량") }}</dt>
                                    <dd>
                                        <span class="pm-energy-text">{{ primaryCstnAmount }}</span>
                                        <span class="pm-energy-amount">&nbsp;kWh_p</span>
                                    </dd>
                                </dl>
                                <dl class="info-style info03">
                                    <dt>{{ $t("1차에너지 생산량") }}</dt>
                                    <dd>
                                        <span class="pm-energy-text">{{ primaryProdAmount }}</span>
                                        <span class="pm-energy-amount">&nbsp;kWh_p</span>
                                    </dd>
                                </dl>
                            </div>
                            <div class="img_area">
                                <div :style="bg1Style" class="bg1"></div>
                                <div :style="bg2Style" class="bg2"></div>
                                <div class="info04">
                                    <dl>
                                        <dt :style="cssVars">{{ $t("에너지 소비량") }}</dt>
                                        <dd>
                                            <span class="unit-amount-text">{{ cstnAmount }}</span
                                            ><span class="unit-text">&nbsp;kWh</span>
                                        </dd>
                                    </dl>
                                </div>
                                <div></div>
                            </div>
                            <p class="ex">
                                <i class="material-icons-outlined">info</i
                                >{{ $t("실시간 자립률은 최근 1시간 자료로 계산됩니다.") }}
                            </p>
                        </div>
                    </div>
                </div>
                <!--// kbet-card-info -->
            </drop>
        </div>

        <b-modal ref="modalSetting" size="sm" title="설정 - 실시간 자립률" bodyClass="p-t-0 p-b-0" hide-footer>
            <self-rate-new-setting :initSetting="setting" @on-modal-cancel="onModalCancel" @on-modal-ok="onModalOK" />
        </b-modal>
    </div>
</template>

<script>
import xeBaseWidget from "@src/views/widget/v1/common/xeBaseWidget.js";
import selfRateNewSetting from "./setting/xeSelfRateNewSetting.vue";

export default {
    props: ["showGrid", "widget", "cellSize", "on-remove-widget"],
    extends: xeBaseWidget,
    components: {
        selfRateNewSetting,
    },
    created() {
        if (!this.widget.bgIcon) {
            this.widget.bgIcon = "material flash_on";
        }
        if (!this.widget.bldImage) {
            this.widget.bldImage = "bg-icon03.png";
        }

        if (!this.widget.zoneIdx) {
            this.widget.zoneIdx = 1;
        }

        // console.log("xeSelfRateNew widget created");
    },
    mounted() {
        // console.log("xeSelfRateNew widget mounted");
    },
    data() {
        return {
            cstnAmount: null,
            primaryCstnAmount: null,
            primaryProdAmount: null,
            selfRenceRate: null,
            themeColor: global.xe.parseBool(localStorage.contentInverse)
                ? require("../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json").dark
                : require("../../../components/vue-chartjs/Sample/ChartOptions/DashboardChartColor.json").light,
            energyIntensityLevel: 0,
            energyConsumptLevel: 0,
        };
    },
    computed: {
        setting() {
            return JSON.parse(JSON.stringify(this.widget));
        },
        cssVars() {
            return {
                "--icon-bg": "url(/assets/xems/common/widget/selfRate/" + this.widget.bldImage + ") 0 0 no-repeat",
            };
        },
        animationDurationBg1() {
            let animationDuration;
            if (this.energyConsumptLevel === 0) {
                animationDuration = "45s";
            } else if (this.energyConsumptLevel === 1) {
                animationDuration = "35s";
            } else if (this.energyConsumptLevel === 2) {
                animationDuration = "25s";
            } else if (this.energyConsumptLevel === 3) {
                animationDuration = "15s";
            } else if (this.energyConsumptLevel >= 4) {
                animationDuration = "5s";
            }
            return {
                animation: `linear ${animationDuration} infinite loader`,
            };
        },
        animationDurationBg2() {
            let animationDuration;
            if (this.energyConsumptLevel == 0) {
                animationDuration = "45s";
            } else if (this.energyConsumptLevel == 1) {
                animationDuration = "35s";
            } else if (this.energyConsumptLevel == 2) {
                animationDuration = "25s";
            } else if (this.energyConsumptLevel == 3) {
                animationDuration = "15s";
            } else if (this.energyConsumptLevel >= 4) {
                animationDuration = "5s";
            }
            return {
                animation: `linear ${animationDuration} infinite loaderInner`,
            };
        },
        colorFilter() {
            let colorFilter;
            if (this.energyIntensityLevel == 0) {
                colorFilter = "grayscale(90%)";
            } else if (this.energyIntensityLevel == 1) {
                colorFilter = "grayscale(65%)";
            } else if (this.energyIntensityLevel == 2) {
                colorFilter = "grayscale(50%)";
            } else if (this.energyIntensityLevel == 3) {
                colorFilter = "grayscale(0%)";
            }
            return {
                filter: colorFilter,
            };
        },
        bg1Style() {
            return {
                ...this.animationDurationBg1,
                // ...this.colorFilter,
            };
        },
        bg2Style() {
            return {
                ...this.animationDurationBg2,
                ...this.colorFilter,
            };
        },
    },
    methods: {
        initSetting() {},
        applyData(data) {
            if (!data) return;

            if (data.cstnAmount == null || data.cstnAmount == undefined) this.cstnAmount = "-";
            else {
                this.cstnAmount = Number.isInteger(data.cstnAmount) ? data.cstnAmount : data.cstnAmount.toFixed(1);
            }

            if (data.primaryCstnAmount == null || data.primaryCstnAmount == undefined) this.primaryCstnAmount = "-";
            else {
                this.primaryCstnAmount = Number.isInteger(data.primaryCstnAmount)
                    ? data.primaryCstnAmount
                    : data.primaryCstnAmount.toFixed(1);
            }

            if (data.primaryProdAmount == null || data.primaryProdAmount == undefined) this.primaryProdAmount = "-";
            else {
                this.primaryProdAmount = Number.isInteger(data.primaryProdAmount)
                    ? data.primaryProdAmount
                    : data.primaryProdAmount.toFixed(1);
            }

            if (data.selfRenceRate == null || data.selfRenceRate == undefined) this.selfRenceRate = "-";
            else {
                // 자립률이 100 이상이거나 0이면 소수점 제거
                if (data.selfRenceRate >= 100 || data.selfRenceRate == 0)
                    this.selfRenceRate = data.selfRenceRate.toFixed(0);
                else {
                    this.selfRenceRate = Number.isInteger(data.selfRenceRate)
                        ? data.selfRenceRate
                        : data.selfRenceRate.toFixed(1);
                }
            }

            this.energyIntensityLevel = data.energyIntensityLevel;
            this.energyConsumptLevel = data.energyConsumptLevel;
        },
    },
};
</script>

<style scoped>
.bg1 {
    position: absolute;
    top: 0;
    left: 9px;
    width: 251px;
    height: 250px;
    background: url(/assets/xems/common/widget/selfRate/circle_bg_01.png) 0 0 no-repeat;
}
.bg2 {
    position: absolute;
    top: 19px;
    left: 28px;
    width: 213px;
    height: 213px;
    background: url(/assets/xems/common/widget/selfRate/circle_bg_02.png) 0 0 no-repeat;
}
</style>
