<template>
    <div>
        <!-- 타이틀 + 서치박스 -->

        <div class="row" v-show="page.search.display">
            <div class="col-md-12">
                <search-box
                    ref="searchBox"
                    :searchOpt="page.search"
                    :isSearchable="isSearchable"
                    @search="onSearch"
                    @download-csv="onDownloadCsv"
                ></search-box>
            </div>
        </div>

        <div class="row" v-xe-pm.E v-show="$store.getters.showErrorPoint && errorPoints.length">
            <div class="col-md-12">존재하지 않는 관제점</div>
            <div class="col-md-12">{{ errorPoints }}</div>
        </div>

        <!-- <div>
      <button type="submit" @click="onRefresh" >갱신 버튼</button>
    </div> -->

        <!-- 드롭존:상단 -->
        <div class="row m-t-40" :class="{ hide: !isEditMode }">
            <div class="col-md-12">
                <drop class="xe-drop-me" @drop="onLayoutDrop(...arguments)">
                    <h4 class="text-center">{{ $t("위젯을 여기에 드롭해 주세요.") }}</h4>
                </drop>
            </div>
        </div>

        <!-- edit 모드일때의 context 메뉴 -->
        <vue-context ref="ctxMenu">
            <template slot-scope="child" v-if="child.data">
                <li v-if="child.data.type !== 'xeForecast'">
                    <a href="javascript:;" @click.prevent="onSettingWidget(child.data)">
                        <i class="fa fa-edit"></i> {{ $t("수정") }}</a
                    >
                </li>
                <li>
                    <a href="javascript:;" @click.prevent="onDeleteWidget(child.data)">
                        <i class="fa fa-trash-alt"></i> {{ $t("삭제") }}</a
                    >
                </li>
            </template>
        </vue-context>

        <!-- 위젯 -->
        <div class="row" v-if="isShow">
            <div class="col-12" ref="screen">
                <!-- 편집모드 -->
                <template v-if="isEditMode">
                    <grid-layout
                        class="gridlayout"
                        :layout.sync="page.widgets"
                        :col-num="colNum"
                        :row-height="30"
                        :is-draggable="true"
                        :is-resizable="true"
                        :responsive="true"
                        :cols="{ lg: 24, md: 16, sm: 10, xs: 5, xxs: 2 }"
                        :vertical-compact="true"
                        :use-css-transforms="true"
                    >
                        <grid-item
                            v-for="widget in page.widgets"
                            :key="widget.id"
                            :x="widget.x"
                            :y="widget.y"
                            :w="widget.w"
                            :h="widget.h"
                            :i="widget.i"
                            :minH="widget.minH"
                            :minW="widget.minW"
                            :maxH="widget.maxH"
                            :maxW="widget.maxW"
                        >
                            <!-- <dnd-grid-container :layout.sync="page.widgets" :bubbleUp="false" :cellSize="cellSize" :margin="page.cellSize.margin || 0">  
            <dnd-grid-box :boxId="widget.id" v-for="widget in (page.widgets)" :key="widget.id" > -->
                            <div @contextmenu.prevent="openContextMenu($event, widget)" class="heightMAX">
                                <xe-chart-donut
                                    v-if="widget.type == 'xeChartDonut'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-chart-gauge
                                    v-if="widget.type == 'xeChartGauge'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-chart-line
                                    v-if="widget.type == 'xeChartLine'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-chart-pie
                                    v-if="widget.type == 'xeChartPie'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-chart-radar
                                    v-if="widget.type == 'xeChartRadar'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-chart-scatter
                                    v-if="widget.type == 'xeChartScatter'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-chart-horizontal-bar
                                    v-if="widget.type == 'xeChartHorizontalBar'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-grid-table
                                    v-if="widget.type == 'xeGridTable'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-html
                                    v-if="widget.type == 'xeHtml'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-pivot-table
                                    v-if="widget.type == 'xePivotTable'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-energy-card
                                    v-if="widget.type == 'xeEnergyCard'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-simple-card
                                    v-if="widget.type == 'xeSimpleCard'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-goal-card
                                    v-if="widget.type == 'xeGoalCard'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-rank-card
                                    v-if="widget.type == 'xeRankCard'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-image
                                    v-if="widget.type == 'xeImage'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-cctv
                                    v-if="widget.type == 'xeCctv'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-realtime
                                    v-if="widget.type == 'xeRealtime'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-alarm-history
                                    v-if="widget.type == 'xeAlarmHistory'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :cellSize="cellSize"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-todo-widget
                                    v-if="widget.type == 'xeTodo'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :cellSize="cellSize"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-weather
                                    v-if="widget.type == 'xeWeather'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :cellSize="cellSize"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                    :showGrid="false"
                                />

                                <!-- new Widget -->
                                <xe-goal-usage-chart
                                    v-if="widget.type == 'xeGoalUsageChart'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-usage-chart
                                    v-if="widget.type == 'xeUsageChart'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-peek-card
                                    v-if="widget.type == 'xePeekCard'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-line
                                    v-if="widget.type == 'xeLine'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-area
                                    v-if="widget.type == 'xeArea'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-pie
                                    v-if="widget.type == 'xePie'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-doughnut
                                    v-if="widget.type == 'xeDoughnut'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-compare-chart
                                    v-if="widget.type == 'xeCompareChart'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-bar
                                    v-if="widget.type == 'xeBar'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />
                                <xe-horizontal-bar
                                    v-if="widget.type == 'xeHorizontalBar'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-biz-info
                                    v-if="widget.type == 'xeBizInfo'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-self-rate
                                    v-if="widget.type == 'xeSelfRate'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-self-rate-new
                                    v-if="widget.type == 'xeSelfRateNew'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-self-rate-toc
                                    v-if="widget.type == 'xeSelfRateToc'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-indoor-env-info
                                    v-if="widget.type == 'xeIndoorEnvInfo'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-forecast
                                    v-if="widget.type == 'xeForecast'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-progress-card
                                    v-if="widget.type == 'xeProgressCard'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-compare-card
                                    v-if="widget.type == 'xeCompareCard'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-self-rate-card
                                    v-if="widget.type == 'xeSelfRateCard'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-carbon-saving
                                    v-if="widget.type === 'xeCarbonSaving'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                ></xe-carbon-saving>

                                <xe-data-card
                                    v-if="widget.type == 'xeDataCard'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />
                            </div>
                        </grid-item>
                    </grid-layout>
                    <!-- </dnd-grid-box>
          </dnd-grid-container> -->
                </template>
                <template v-else>
                    <!-- 일반모드 -->
                    <div>
                        <!-- style="position:absolute; "  v-for="widget in (page.widgets)" :key="widget.id"
            :style="[
              { top:  page.cellSize.h * widget.position.y + 'px' },
              { left: page.cellSize.w * widget.position.x + 'px'},
              { width: page.cellSize.w * widget.position.w + 'px'},
              { height:   page.cellSize.h * widget.position.h + 'px' }
            ]"  -->

                        <grid-layout
                            class="gridlayout"
                            :layout.sync="page.widgets"
                            :col-num="colNum"
                            :row-height="30"
                            :is-draggable="false"
                            :is-resizable="false"
                            :responsive="true"
                            :cols="{ lg: 24, md: 16, sm: 10, xs: 5, xxs: 2 }"
                            :vertical-compact="true"
                            :use-css-transforms="true"
                        >
                            <grid-item
                                v-for="widget in page.widgets"
                                :key="widget.i"
                                :x="widget.x"
                                :y="widget.y"
                                :w="widget.w"
                                :h="widget.h"
                                :i="widget.i"
                                :minH="widget.minH"
                                :minW="widget.minW"
                                :maxH="widget.maxH"
                                :maxW="widget.maxW"
                                class="grid-item"
                            >
                                <xe-goal-usage-chart
                                    v-if="widget.type == 'xeGoalUsageChart'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-chart-donut
                                    v-if="widget.type == 'xeChartDonut'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-chart-gauge
                                    v-if="widget.type == 'xeChartGauge'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-chart-line
                                    v-if="widget.type == 'xeChartLine'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-chart-pie
                                    v-if="widget.type == 'xeChartPie'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-chart-radar
                                    v-if="widget.type == 'xeChartRadar'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-chart-scatter
                                    v-if="widget.type == 'xeChartScatter'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-chart-horizontal-bar
                                    v-if="widget.type == 'xeChartHorizontalBar'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-grid-table
                                    v-if="widget.type == 'xeGridTable'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-html
                                    v-if="widget.type == 'xeHtml'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-pivot-table
                                    v-if="widget.type == 'xePivotTable'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-energy-card
                                    v-if="widget.type == 'xeEnergyCard'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-simple-card
                                    v-if="widget.type == 'xeSimpleCard'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-goal-card
                                    v-if="widget.type == 'xeGoalCard'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-rank-card
                                    v-if="widget.type == 'xeRankCard'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-image
                                    v-if="widget.type == 'xeImage'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-cctv
                                    v-if="widget.type == 'xeCctv'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-realtime
                                    v-if="widget.type == 'xeRealtime'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-alarm-history
                                    v-if="widget.type == 'xeAlarmHistory'"
                                    :widget="widget"
                                    :cellSize="cellSize"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-todo-widget
                                    v-if="widget.type == 'xeTodo'"
                                    :widget="widget"
                                    :cellSize="cellSize"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-weather
                                    v-if="widget.type == 'xeWeather'"
                                    :widget="widget"
                                    :cellSize="cellSize"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                    :showGrid="false"
                                />

                                <!-- new Widget -->
                                <xe-usage-chart
                                    v-if="widget.type == 'xeUsageChart'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-peek-card
                                    v-if="widget.type == 'xePeekCard'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-line
                                    v-if="widget.type == 'xeLine'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-area
                                    v-if="widget.type == 'xeArea'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-pie
                                    v-if="widget.type == 'xePie'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-doughnut
                                    v-if="widget.type == 'xeDoughnut'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-compare-chart
                                    v-if="widget.type == 'xeCompareChart'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-bar
                                    v-if="widget.type == 'xeBar'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-horizontal-bar
                                    v-if="widget.type == 'xeHorizontalBar'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-biz-info
                                    v-if="widget.type == 'xeBizInfo'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-self-rate
                                    v-if="widget.type == 'xeSelfRate'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-self-rate-new
                                    v-if="widget.type == 'xeSelfRateNew'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-self-rate-toc
                                    v-if="widget.type == 'xeSelfRateToc'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-indoor-env-info
                                    v-if="widget.type == 'xeIndoorEnvInfo'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-forecast
                                    v-if="widget.type == 'xeForecast'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-progress-card
                                    v-if="widget.type == 'xeProgressCard'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-compare-card
                                    v-if="widget.type == 'xeCompareCard'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-self-rate-card
                                    v-if="widget.type == 'xeSelfRateCard'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />

                                <xe-carbon-saving
                                    v-if="widget.type === 'xeCarbonSaving'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    :contextMenu="contextMenu"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                ></xe-carbon-saving>

                                <xe-data-card
                                    v-if="widget.type == 'xeDataCard'"
                                    ref="xeWidgets"
                                    :widget="widget"
                                    :searchOpt="page.search"
                                    :searchBoxCond="searchBoxCond"
                                    :searchLogs="searchLogs"
                                    :cellSize="cellSize"
                                    @on-handle-title-drop="handleTitleDrop(...arguments)"
                                    @on-change-widget="onChangeWidget"
                                    @on-remove-widget="onDeleteWidget(widget)"
                                />
                            </grid-item>
                        </grid-layout>
                    </div>
                </template>
            </div>
        </div>

        <!-- 드롭존:하단 -->
        <div class="row m-t-40" :class="{ hide: !isEditMode }">
            <div class="col-md-12">
                <drop class="xe-drop-me" @drop="onLayoutDrop(...arguments)">
                    <h4 class="text-center">{{ $t("위젯을 여기에 드롭해 주세요.") }}</h4>
                </drop>
            </div>
        </div>

        <todo-docs />
    </div>
</template>

<docs>

</docs>

<style scoped>
.xe-todo-docs {
    position: relative;
    top: 0px;
    left: 0px;
    width: 600px;
    min-height: 200px;
    background-color: burlywood;
    z-index: 100;
}
</style>

<style scoped>
.grid-item {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    border-radius: 5px !important;
}
/* 드롭존 스타일 */
.xe-drop-me {
    min-height: 50px;
    margin-bottom: 20px;
    padding-top: 12px;
    border: dashed 1px;
    min-height: 50px;
    background-color: whitesmoke;
}

/* 이하 어디서 사용중인지 모르겠음.. */
[draggable] {
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
li {
    color: black;
}
.dragging {
    opacity: 0.8;
    color: #6894d1;
}
.drag-over {
    outline: 1px solid red;
}
.drag-enter {
    color: #c93742;
}
/* 동작하지 않음.. */
.dropdown-menu {
    left: -52px !important;
}
</style>

<script>
import Vue from "vue";
import { Drop } from "vue-drag-drop";
import { Container, Box } from "@dattn/dnd-grid";
import "@dattn/dnd-grid/dist/dnd-grid.css";
import { VueContext } from "vue-context";
import axios from "axios";
//import PageOptions          from "../../config/PageOptions.vue";
import backEndApi from "@api/backEndApi.js";
import SearchBox from "@src/views/widget/SearchBox.vue";
import xeCctv from "@src/views/widget/v1/xeCctv.vue";
import xeChartDonut from "@src/views/widget/v1/xeChartDonut.vue";
import xeChartGauge from "@src/views/widget/v1/xeChartGauge.vue";
import xeChartHorizontalBar from "@src/views/widget/v1/xeChartHorizontalBar.vue";
import xeChartLine from "@src/views/widget/v1/xeChartLine.vue";
import xeChartPie from "@src/views/widget/v1/xeChartPie.vue";
import xeChartRadar from "@src/views/widget/v1/xeChartRadar.vue";
import xeChartScatter from "@src/views/widget/v1/xeChartScatter.vue";
import xeEnergyCard from "@src/views/widget/v1/xeEnergyCard.vue";
import xeGoalCard from "@src/views/widget/v1/xeGoalCard.vue";
import xeGridTable from "@src/views/widget/v1/xeGridTable.vue";
import xeHtml from "@src/views/widget/v1/xeHtml.vue";
import xeImage from "@src/views/widget/v1/xeImage.vue";
import xePivotTable from "@src/views/widget/v1/xePivotTable.vue";
import xeRankCard from "@src/views/widget/v1/xeRankCard.vue";
import xeRealtime from "@src/views/widget/v1/xeRealtime.vue";
import xeSimpleCard from "@src/views/widget/v1/xeSimpleCard.vue";

import xeAlarmHistory from "@src/views/widget/v1/xeAlarmHistoryWidget.vue";
import xeTodoWidget from "@src/views/widget/v1/xeTodoWidget.vue";
import xeWeather from "@src/views/widget/v1/xeWeatherWidget.vue";

// new Widget
import xeLine from "./v1/xeLine.vue";
import xeArea from "./v1/xeArea.vue";
import xePie from "./v1/xePie.vue";
import xeDoughnut from "./v1/xeDoughnut.vue";
import xePeekCard from "./v1/xePeekCard.vue";
import xeUsageChart from "./v1/xeUsageChart.vue";
import xeGoalUsageChart from "./v1/xeGoalUsageChart.vue";

import xeBar from "./v1/xeBar.vue";
import xeHorizontalBar from "./v1/xeHorizontalBar.vue";
import xeBizInfo from "./v1/xeBizInfo.vue";
import xeSelfRate from "./v1/xeSelfRate.vue";
import xeSelfRateNew from "./v1/xeSelfRateNew.vue";
import xeSelfRateToc from "./v1/xeSelfRateToc.vue";
import xeSelfRateCard from "./v1/xeSelfRateCard.vue";
import xeProgressCard from "./v1/xeCompareCard.vue";
import xeCompareCard from "./v1/xeIodCard.vue";
import xeDataCard from "./v1/xeDataCard.vue";
import xeCompareChart from "./v1/xeCompareChart.vue";
import xeIndoorEnvInfo from "./v1/xeIndoorEnvInfo.vue";
import xeForecast from "./v1/xeForecast.vue";

import xeCarbonSaving from "./v1/xeCarbonSaving.vue";

import { GridLayout, GridItem } from "vue-grid-layout";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default global.module({
    components: {
        Drop,
        DndGridContainer: Container,
        DndGridBox: Box,
        VueContext,
        SearchBox,
        xeCctv,
        xeChartDonut,
        xeChartGauge,
        xeChartHorizontalBar,
        xeChartLine,
        xeChartPie,
        xeChartRadar,
        xeChartScatter,
        xeEnergyCard,
        xeGoalCard,
        xeGridTable,
        xeHtml,
        xeImage,
        xePivotTable,
        xeRankCard,
        xeRealtime,
        xeSimpleCard,
        xeAlarmHistory,
        xeTodoWidget,
        xeWeather,
        GridLayout,
        GridItem,
        //new Widget
        xeLine,
        xeArea,
        xePie,
        xeDoughnut,
        xeBar,
        xeHorizontalBar,
        xeBizInfo,
        xeProgressCard,
        xeCompareCard,
        xeDataCard,
        xePeekCard,
        xeUsageChart,
        xeGoalUsageChart,
        xeCompareChart,
        xeSelfRate,
        xeSelfRateNew,
        xeSelfRateToc,
        xeSelfRateCard,
        xeIndoorEnvInfo,
        xeForecast,
        xeCarbonSaving,
    },
    props: ["page"],
    data() {
        return {
            searchBoxDocs: SearchBox.__docs,

            cardIndex: 0,
            temp: {
                x: null,
                y: null,
                arrIndex: null,
            },
            card: [],

            isShow: false,

            colNum: 21,

            // searchBox가 조회시 생성한 조회설정값
            searchBoxCond: {
                // SearchBox 초기화 값과 동일해야 함..
                type: 4,
                period: {
                    sDt: new Date().format("yyyy-MM-dd"),
                    eDt: new Date().addDay(5).format("yyyy-MM-dd"),
                    points: {},
                },
                compare: {
                    sDt: new Date().format("yyyy-MM-dd"),
                    eDt: new Date().addDay(5).format("yyyy-MM-dd"),
                    points: {},
                },
            },
            // 조회결과
            searchLogs: [],
            // ctx 메뉴
            contextMenu: { widget: {}, isShowSetting: false },

            widgetConds: [],
            searchConds: [],

            isSearchable: true,
            searchCnt: 0,

            // axios 취소 관련 토큰 생성
            cancelToken: axios.CancelToken,
            cancelObj: {},

            screenWidth: -1,

            // 자동 화면 갱신 주기 조정
            onRefreshObj: null,
            refreshTerm: 1 * 60 * 1000,

            liveAM: {},
            errorPoints: [],

            breakpoint: "lg",
            popupMessages,
        };
    },
    computed: {
        isEditMode() {
            return this.$store.getters.isEditMode;
        },
        cellSize() {
            // return  this.page.cellSize ? this.page.cellSize : { w: 10, h: 10 };
            // 10은 cell의 기본크기
            // 1654는 기본 전체화면일때의 크기

            // 모바일 환경에서 사이즈 고려 안됨
            if (this.isMobile) return { w: 10, h: 10 };

            let x = 10 * (this.boxRate / 100);
            return { w: x, h: x };
        },
        isFullScreen() {
            return this.pageOptions.pageEmpty;
        },
        boxRate() {
            let defaultScreenSize = 1654;

            return this.screenWidth.percentage(defaultScreenSize);
        },
    },
    watch: {
        "$store.getters.isEditMode": function() {
            // 수정모드에서는 위젯들이 dndgrid상에서 움직일 수 있도록 풀어준다.
            this.page.widgets.forEach((widget) => {
                widget.pinned = !this.isEditMode;
            });
        },
        isFullScreen: function() {
            this.initScreenSize();
        },
    },

    mounted() {
        this.initSetting();
        // this.$nextTick(function () {
        //   this.onRefresh();
        // });

        // this.$nextTick(function() {
        //   window.addEventListener('resize', this.initScreenSize);
        //   this.initScreenSize()
        // })
    },
    // updated : function () { //DOM 어
    // this.$nextTick(function () {
    //   // Code that will run only after the
    //   // entire view has been re-rendered
    //   })
    // },

    beforeDestroy() {
        // window.removeEventListener('resize', this.initScreenSize);

        // 화면에 조회가 걸려있다면 cancel 시킨다.
        Object.keys(this.cancelObj).forEach((key) => {
            if (this.cancelObj[key] != null)
                // console.log(`Axios Cancel : Destroy All Cancel Source. key: ${key}`)
                this.cancelObj[key].cancel("page destroy");
        });

        Vue.nextTick(() => {
            this.isSearchable = true;
        });
    },
    methods: {
        increment() {
            return ++this.cardIndex;
        },
        // onPageResized(){
        //   this.initScreenSize()
        // },
        // initScreenSize(){
        //   const { width } = this.$refs.screen.getBoundingClientRect();

        //   this.screenWidth = width;

        //   let x = 10 * (this.boxRate / 100);

        //   this.page.cellSize.w = x;
        //   this.page.cellSize.h = x;
        // },
        // 초기화
        initSetting() {
            this.setCardWidgetIndex();
            this.liveAM = this.readStorage("liveAM");

            // 조회조건 초기화
            if (this.isEmpty(this.page.search)) {
                this.page.search = {
                    isCompare: false,
                    timeType: 4,
                    isLoadSearch: false,
                    display: true,
                };
            }

            if (this.isEmpty(this.page.search.isCompare)) this.page.search.isCompare = false; // 비교모드 사용여부
            if (this.isEmpty(this.page.search.timeType)) this.page.search.timeType = 4; // 시간 유형
            if (this.isEmpty(this.page.search.isLoadSearch)) this.page.search.isLoadSearch = false; // 화면로드시 조회
            if (this.isEmpty(this.page.search.display)) this.page.search.display = false; // 조회박스 표시여부

            this.page.search.timeType = parseInt(this.page.search.timeType);

            // dndgrid 설정값 초기화
            if (!this.page.cellSize) this.page.cellSize = { w: 10, h: 10, margin: 0 };

            // 윗젯들 초기화
            if (this.page.widgets) {
                this.page.widgets.forEach(this.initWidget);
            } else {
                this.page.widgets = [];
            }

            // isLoadSearch 화면로드시 조회는 SearchBox에서 호출됨.
            // 위젯이 준비가 되었으므로 화면에 표시한다.
            this.isShow = true;

            // 서치박스 없다면 주기적 화면 갱신 동작
            if (!this.page.search.display) {
                this.onRefreshObj = setInterval(() => {
                    this.onRefresh();
                }, this.refreshTerm);
            } else {
                if (this.onRefreshObj != null) {
                    clearInterval(this.onRefreshObj);
                    this.onRefreshObj = null;
                }
            }
        },
        initWidget(widget) {
            // dndgird에 위젯의 기본값을 설정한다.
            if (!widget.id) {
                // id가 없으면, 중복되지 않는 id를 생성한다.
                let i = 99;
                let j = this.page.widgets.length;
                widget.id = `W${(i + 1).pad(3)}-${(j + 1).pad(3)}-${Math.random()
                    .toString(36)
                    .substring(3)}`; // 임의 문자열 생성 추가
                widget.i = widget.id;
            }

            widget.hidden = false;
            widget.pinned = !this.isEditMode;

            if (!widget.position) {
                widget.position = this.getNextPosition(widget);

                switch (widget.type) {
                    case "xeProgressCard": {
                        widget.minW = 2;
                        widget.minH = 4;
                        widget.maxW = 24;
                        widget.maxH = 7;
                        break;
                    }

                    case "xeCompareCard":
                    case "xeSelfRateCard": {
                        widget.minW = 3;
                        widget.minH = 4;
                        widget.maxW = 24;
                        widget.maxH = 7;
                        widget.index = ++this.cardIndex;
                        break;
                        // widget.minW = 3; widget.minH = 7; widget.maxW = 12; widget.maxH = 15; break;
                    }
                    case "xeGoalUsageChart": {
                        widget.minW = 7;
                        widget.minH = 7;
                        widget.maxW = 24;
                        widget.maxH = 20;
                        break;
                    }

                    case "xeDataCard": {
                        break;
                    }

                    case "xeDoughnut": {
                        widget.minW = 2;
                        widget.minH = 2;
                        widget.maxW = 24;
                        widget.maxH = 500;
                        break;
                    }

                    case "xeHorizontalBar":
                    case "xeGridTable":
                    case "xePie":
                    case "xeLine":
                    case "xeArea":
                    case "xePeekCard":
                    case "xeUsageChart":
                    case "xeBar":
                    case "xeCompareChart":
                    case "xeBizInfo": {
                        widget.minW = 5;
                        widget.minH = 4;
                        widget.maxW = 24;
                        widget.maxH = 500;
                        break;
                    }
                    case "xeSelfRate":
                    case "xeSelfRateNew": {
                        widget.minW = 7;
                        widget.minH = 9;
                        widget.maxW = 21;
                        widget.maxH = 27;
                        break;
                    }
                    case "xeCarbonSaving": {
                        widget.minW = 7;
                        widget.minH = 9;
                        widget.maxW = 21;
                        widget.maxH = 27;
                        break;
                    }
                    case "xeSelfRateToc": {
                        widget.minW = 8;
                        widget.minH = 16;
                        widget.maxW = 24;
                        widget.maxH = 48;
                        break;
                    }
                    case "xeIndoorEnvInfo": {
                        widget.minW = 6;
                        widget.minH = 5;
                        widget.maxW = 21;
                        widget.maxH = 27;
                        break;
                    }
                    case "xeForecast": {
                        widget.minW = 6;
                        widget.minH = 5;
                        widget.maxW = 21;
                        widget.maxH = 27;
                        break;
                    }
                    default: {
                        widget.minW = 2;
                        widget.minH = 1;
                        widget.maxW = 24;
                        widget.maxH = 500;
                    }
                }

                widget.x = (this.page.widgets.length * 3) % (this.colNum || 12);
                widget.y = this.page.widgets.length + (this.colNum || 12);
                widget.w = widget.minW;
                widget.h = widget.minH;
            }

            switch (widget.type) {
                case "xeProgressCard": {
                    widget.index = this.cardIndex++;
                    break;
                }
                case "xeCompareCard":
                case "xeSelfRateCard": {
                    widget.index = this.cardIndex++;
                    break;
                    // widget.minW = 3; widget.minH = 7; widget.maxW = 12; widget.maxH = 15; break;
                }
                case "xeDataCard": {
                    widget.index = this.cardIndex++;
                    break;
                }
            }

            return widget;
        },
        setCardWidgetIndex() {
            this.page.widgets.forEach((widget, index) => {
                switch (widget.type) {
                    // case "xeDataCard":
                    case "xeProgressCard":
                    case "xeCompareCard":
                    case "xeSelfRateCard": {
                        this.card.push({
                            arrIndex: index,
                            x: widget.x,
                            y: widget.y,
                        });
                        break;
                    }
                }
            });
            this.cardSort();
        },
        cardSort() {
            var ySort = this.card;

            var originIdx = [];
            var sortData = [];

            ySort.forEach((element) => {
                originIdx.push(element.arrIndex);
            });

            //this.card y축 정렬
            for (var k = 0; k < this.card.length; k++) {
                for (var l = 0; l < this.card.length; l++) {
                    if (this.card[k].y == this.card[l].y) {
                        continue;
                    }

                    if (this.card[k].y < this.card[l].y) {
                        var tempY = ySort[l];
                        ySort[l] = ySort[k];
                        ySort[k] = tempY;
                    }
                }
            }

            var xSort = ySort;
            for (var i = 0; i < ySort.length; i++) {
                for (var j = 0; j < ySort.length; j++) {
                    if (ySort[i].y == ySort[j].y) {
                        if (ySort[i].x == ySort[j].x) {
                            continue;
                        }

                        if (ySort[i].x < ySort[j].x) {
                            var tempX = xSort[j];
                            xSort[j] = xSort[i];
                            xSort[i] = tempX;
                        }
                    }
                }
            }

            xSort.forEach((element) => {
                sortData.push(this.page.widgets[element.arrIndex]);
            });

            for (var r = 0; r < this.card.length; r++) {
                this.page.widgets[originIdx[r]] = sortData[r];
            }
        },
        getNextPosition(newWidget) {
            // dndgrid에 마지막위치 이후에 새 위젯이 들어갈 공간의 위치값을 가져온다.
            let maxH = 0;

            this.page.widgets.forEach((widget) => {
                let h = widget.y + widget.h;
                if (h > maxH) maxH = h;
            });

            return { x: 1, y: maxH + 2, w: 40, h: newWidget.defaultHeigth || 30 };
        },
        // ctx 메뉴
        openContextMenu(evt, widget) {
            this.$refs.ctxMenu.open(evt, widget);
        },
        onSettingWidget(widget) {
            this.contextMenu.widget = widget;
            // baseWidget에서 isShowSetting을 watch하고 있다가 팝업을 표시해 줌..
            this.contextMenu.isShowSetting = !this.contextMenu.isShowSetting;
        },
        onDeleteWidget(curWidget) {
            let widgets = this.page.widgets.map((v) => v);
            let idx = widgets.indexOf(curWidget);
            widgets.splice(idx, 1);

            this.page.widgets = widgets;

            this.isShow = false;
            Vue.nextTick(() => {
                this.isShow = true;
            });
        },
        // search box
        onRefresh() {
            // 부모페이지에서 재조회시 호출..
            this.onSearch();
        },
        getPoints() {
            let periodPoints = [];
            let comparePoints = [];

            if (this.page.widgets) {
                this.page.widgets.forEach((widget) => {
                    // series 구조에 따른 타입분류

                    if (
                        widget.type == "xeChartLine" ||
                        widget.type == "xeChartPie" ||
                        widget.type == "xeChartGauge" ||
                        widget.type == "xeChartRadar" ||
                        widget.type == "xeLine" ||
                        widget.type == "xeArea" ||
                        widget.type == "xeBar" ||
                        widget.type == "xeCompareChart" ||
                        widget.type == "xeGoalUsageChart"
                    ) {
                        periodPoints = periodPoints.concat(
                            widget.chart.series.filter((v) => !v.compare || v.compare == false).map((v) => v.point)
                        );
                        comparePoints = comparePoints.concat(
                            widget.chart.series.filter((v) => v.compare).map((v) => v.point)
                        );
                    } else if (
                        widget.type == "xeEnergyCard" ||
                        widget.type == "xeGoalCard" ||
                        widget.type == "xeSimpleCard"
                    ) {
                        let obj = widget.objects;
                        if (obj) {
                            obj.main && obj.main.point ? periodPoints.push(obj.main.point) : null;
                            obj.bottom && obj.bottom.point ? periodPoints.push(obj.bottom.point) : null;
                            obj.goal && obj.goal.point ? periodPoints.push(obj.goal.point) : null;
                        }
                    } else if (widget.type == "xeChartScatter" || widget.type == "xeChartHorizontalBar") {
                        periodPoints = periodPoints.concat(
                            widget.chart.series.filter((v) => !v.compare || v.compare == false).map((v) => v.point)
                        );
                        comparePoints = comparePoints.concat(
                            widget.chart.series.filter((v) => v.compare).map((v) => v.point)
                        );
                        periodPoints = periodPoints.concat(
                            widget.addition.xAxes.point ? [widget.addition.xAxes.point] : []
                        );
                    } else if (widget.type == "xeGridTable" || widget.type == "xePivotTable") {
                        periodPoints = periodPoints.concat(widget.columns.map((v) => v.point));
                        periodPoints = periodPoints.concat(
                            widget.columns.filter((v) => !v.compare || v.compare == false).map((v) => v.point)
                        );
                        comparePoints = comparePoints.concat(
                            widget.columns.filter((v) => v.compare).map((v) => v.point)
                        );
                    } else if (widget.type == "xeChartDonut" || widget.type == "xeRankCard") {
                        periodPoints = periodPoints.concat(widget.chart.series.map((v) => v.point));
                        periodPoints = periodPoints.concat(widget.chart.series.map((v) => v.maxPt));
                        comparePoints = comparePoints.concat(widget.chart.series.map((v) => v.point));
                        comparePoints = comparePoints.concat(widget.chart.series.map((v) => v.maxPt));
                    }
                });
            }

            return {
                periodPoints: periodPoints.filter((v) => v != "DATE_AND_TIME").unique(),
                comparePoints: comparePoints.filter((v) => v != "DATE_AND_TIME").unique(),
            };
        },
        appendUsePoint(useAddr) {
            if (this.errorPoints.filter((v) => v == useAddr).length == 0 && this.isEmpty(this.liveAM[useAddr])) {
                this.errorPoints.push(useAddr);
            }
        },

        onSearch() {
            // 화면 전환시 오류 발생하여 코드 추가 - TypeError: Cannot read property 'conditions' of undefined
            if (this.isEmpty(this.$refs.searchBox) || !this.$refs.searchBox.hasOwnProperty("conditions")) return;

            // console.log("dash board refresh search :::::::::::::::");

            this.searchBoxCond = this.$refs.searchBox.conditions;

            // Date Nomalize 처리
            this.searchBoxCond.period.sDt = this.searchBoxCond.period.sDt.normalize(this.searchBoxCond.type);
            this.searchBoxCond.period.eDt = this.searchBoxCond.period.eDt.normalize(this.searchBoxCond.type);

            if (this.searchBoxCond.isCompare) {
                this.searchBoxCond.compare.sDt = this.searchBoxCond.compare.sDt.normalize(this.searchBoxCond.type);
                this.searchBoxCond.compare.eDt = this.searchBoxCond.compare.eDt.normalize(this.searchBoxCond.type);
            }
            // 위젯별 조건 모으기

            // 기본 baseline    this.searchBoxCond.period,
            // 비교 compare     this.searchBoxCond.compare,
            // Self기본 : now, baseline.sDt, compare.sDt,  "2020-01-01"
            // Self비교 : now, baseline.sDt, compare.sDt

            try {
                // 조회중인지 체크
                this.isSearchable = false;
                this.searchCnt = 0;

                // 조회가 없는 위젯은 조회 카운트에서 제거한다.
                let searchLength = this.$refs.xeWidgets.filter(
                    (v) => !this.isEmpty(v.getWidgetConds(this.searchBoxCond))
                ).length;

                // 위젯별 조회로 변경
                this.$refs.xeWidgets.forEach((widget) => {
                    let searchCond = widget.getWidgetConds(this.searchBoxCond);

                    // 관제점이 존재하지 않은면 경고메세지를 출력한다.
                    searchCond.map((v) => v.points.map((useAddr) => this.appendUsePoint(useAddr)));

                    // let errorPoints = searchCond.map(v => v.points.map(addr => this.isEmpty(addr) || this.isEmpty(this.liveAM[addr]) ? addr : null ) ).flat().filter(v => v).unique();
                    // if(errorPoints.length > 0) console.log(`${widget.widget.type} '${widget.widget.title}' 존재하지 않는 관제점: ${errorPoints.join(",")}`);

                    if (this.cancelObj[widget._uid]) {
                        // console.log(`Axios Cancel. Canceling : ${widget._uid}/${widget.$options._componentTag}`)
                        this.cancelObj[widget._uid].cancel("Operation canceled by the user.");
                    } else {
                        // console.log(`Axios Cancel Regist Cancel Source : ${widget._uid}/${widget.$options._componentTag}`)
                        this.cancelObj[widget._uid] = this.cancelToken.source();
                    }

                    // TODO
                    // 신규 추가 위젯 API 우회 추가
                    // widget.widget.type의 타입이 신규 위젯이랑 같을 시 기존 api말고 새로운 api 호출로 변경 필요

                    if (widget.widget.type === "xeGoalUsageChart") {
                        backEndApi.widgetExtend.targetConsumpt().then((result) => {
                            widget.applyData(result.data);
                        });
                    } else if (widget.widget.type === "xePeekCard") {
                        /*
	              backEndApi.widget.widgetPeakData(widget.widget.zoneIdx).then((result) => {
	                widget.applyData(result.data);
	              });

	              전력피크 위젯 호출 API 변경 2023.02.14 권철규
	            */
                        backEndApi.widget.widgetPowerLoad(widget.widget.ptaddr).then((result) => {
                            widget.applyData(result.data);
                        });
                    } else if (widget.widget.type === "xeUsageChart") {
                        //TODO - 현재 index 값을 고정, 추후 변경 필요
                        backEndApi.widget.widgetConsumptData(widget.widget.zoneIdx).then((result) => {
                            widget.applyData(result.data);
                        });
                    } else if (widget.widget.type === "xeSelfRate" || widget.widget.type === "xeSelfRateNew") {
                        //TODO - 현재 index 값을 고정, 추후 변경 필요

                        backEndApi.widget.selfRenceRate(widget.widget.zoneIdx).then((result) => {
                            widget.applyData(result.data);
                        });
                    } else if (widget.widget.type === "xeCarbonSaving") {
                        // TODO - 탄소절감량 api 작성
                    } else if (widget.widget.type === "xeSelfRateToc") {
                        //TODO - 현재 index 값을 고정, 추후 변경 필요

                        backEndApi.widget.selfRateByRegion().then((result) => {
                            widget.applyData(result.data);
                        });
                    } else if (widget.widget.type === "xeSelfRateCard") {
                        //TODO - 현재 index 값을 고정, 추후 변경 필요
                        // console.log(`WidgetPageV2.onSearch : zoneIdx : ${widget.widget.zoneIdx}`);
                        backEndApi.widget.selfRenceRateMonth(widget.widget.zoneIdx).then((result) => {
                            widget.applyData(result.data);
                        });
                    } else if (widget.widget.type === "xeIndoorEnvInfo") {
                        // backEndApi.widget.irEvmnStatusInfo(widget.widget.equipIdx, widget.widget.equipTypeCode).then((result) => {
                        // 	widget.applyData(result.data);
                        // });
                        console.log("widget.widget", widget.widget);
                        const searchParams = {
                            sysNodeIdx: widget.widget.sysNodeIdx,
                            queryType: widget.widget.queryType,
                        };
                        backEndApi.sysNode.getGroupOprtStatus(searchParams).then((result) => {
                            widget.applyData(result.data);
                        });
                    } else if (widget.widget.type === "xeForecast") {
                        backEndApi.widget.getWeatherCast().then((result) => {
                            widget.applyData(result.data);
                        });
                    } else if (widget.widget.type === "xeBizInfo") {
                        //사업장 정보 일기예보 Data
                        backEndApi.widget.getCurrentWeather().then((result) => {
                            widget.applyData(result.data);
                        });
                    } else {
                        backEndApi.summary
                            .searchDataWithToken(searchCond, null, this.cancelObj[widget._uid])
                            .then(({ data }) => {
                                if (this.$err(data)) return;
                                if (this.isEmpty(data)) return;

                                // 해당 위젯에 직접 바인딩
                                widget.applyData(data);

                                // 조회된 데이터 카운트
                                this.searchCnt++;

                                if (searchLength == this.searchCnt) {
                                    this.isSearchable = true;
                                }
                            })
                            .catch((err) => {
                                if (axios.isCancel(err)) {
                                    console.info("Axios Exception: ", err.message);
                                } else {
                                    this.isSearchable = true;
                                    console.error("onSearch err", err);
                                }
                            })
                            .finally(() => {
                                this.cancelObj[widget._uid] = null;
                                // console.log(`Axios Cancel. Unregister Cancle Object : ${widget._uid}/${widget.$options._componentTag}`)
                                // 재조회?
                            });
                    }
                });
            } catch (err) {
                console.log("onSearch err", err.message);
            }
        },
        onChangeWidget(curWidget) {
            // 설정모달에서 OK를 누르면 위젯정보가 변경된 것이므로, 해당위젯을 찾아서 실제정보를 저장한다.
            let idx = this.page.widgets.findIndex((v) => v.i === curWidget.i);
            Vue.set(this.page.widgets, idx, curWidget);
        },
        // layout
        handleTitleDrop(transfer, self) {
            // 위젯 변경 (설정값은 카피 못함 기존 설정 초기화됨)
            let newWidget = transfer.item;

            newWidget.i = self.i;
            newWidget = self;

            this.page.widgets.forEach((widget, idx) => {
                if (widget.i == self.i) this.page.widgets[idx] = newWidget;
            });

            this.isShow = false;
            Vue.nextTick(() => {
                this.isShow = true;
            });
        },
        onLayoutDrop(transfer) {
            try {
                let newWidget = {};
                newWidget = this.merge({}, transfer.item, {
                    panel: { display: transfer.item.panel.display },
                });

                delete newWidget.icon;
                delete newWidget.color;
                delete newWidget.effect;
                delete newWidget.defaultHeigth;

                this.initWidget(newWidget);

                this.page.widgets.push(newWidget);
            } catch (err) {
                console.log("onLayoutDrop err", err.message);
            }
        },
        onDownloadCsv() {
            // TODO: 전체 위젯 for문 돌면서 후처리된 데이터를 수집하여 다운로드 시켜야야 함.. 현재 불가능

            function csvExport(exportData) {
                if (!exportData || !exportData.data) return;

                let arrData = exportData.data;
                let csvContent = "data:text/csv;charset=utf-8,";
                csvContent += [
                    Object.keys(arrData[0]).join(","),
                    ...arrData.map((item) => Object.values(item).join(",")),
                ]
                    .join("\n")
                    .replace(/(^\[)|(\]$)/gm, "");

                //console.log("csvContent ----------------------- ", arrData, Object.keys(arrData[0]).join(","));

                const data = encodeURI(csvContent);
                const link = document.createElement("a");
                link.setAttribute("href", data);
                link.setAttribute("download", `${exportData.title}.csv`);
                link.click();
            }

            let csv = {
                title: this.page.title ? this.page.title : "export",
                data: null,
            };

            let doubleRows = [];

            this.$refs.xeWidgets.forEach((widget) => {
                // let searchCond = widget.getWidgetConds(this.searchBoxCond);

                if (widget.widgetLogs.length == 0) return;

                //console.log("widgetLogs ----------------------- ", widget.widgetLogs.length, widget.widgetLogs);

                let widgetRows = widget.widgetLogs.map((log, i) => {
                    let dateTimeName = "Baseline";
                    if (i == 1) dateTimeName = "Comparison";

                    return log.dates.map((dateTime, j) => {
                        if (dateTime.length > 1) dateTime = `${dateTime[1] || ""} ${dateTime[0] || ""}`;

                        //Points
                        let row = {};
                        row[dateTimeName] = dateTime;
                        Object.keys(log.points).map((point) => {
                            row[i == 1 ? point + "_C" : point] = log.points[point][j];
                        });
                        return row;
                    });
                });

                doubleRows.range(widgetRows);
            });

            if (doubleRows.length < 1) {
                this.alertNoti(popupMessages.COMMON_NO_DATA_POPUP_MESSAGE);
                return;
            }
            // BaseLine 조회일 경우
            if (doubleRows.length <= 1) {
                csv.data = doubleRows[0] ? doubleRows[0] : [];

                csvExport(csv);
                return;
            }

            // 두개의 배열길이 다른경우, 더 긴 배열에 길이만큼 for문이 돌아서 row가 생성되어야 함.
            let baseRows = doubleRows[0];
            let compareRows = doubleRows[1];

            if (baseRows.length > compareRows.length) {
                csv.data = baseRows.map((row, idx) => {
                    let base = baseRows[idx];
                    let compare = compareRows[idx];
                    return Object.assign(base, compare);
                });
            } else {
                csv.data = compareRows.map((row, idx) => {
                    let base = baseRows[idx];
                    let compare = compareRows[idx];

                    return Object.assign(compare, base);
                });
            }

            csvExport(csv);
        },
    },
});
</script>
